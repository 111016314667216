import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const BLM = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Black Lives Matter Press Release" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
        <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p> In Solidarity w/ Black Lives Matter </p>
        </div>
        <div className="byLine">
          <p> By: Little Sure, You're Right! (Pub. Jun. 13, 2020) </p>
        </div>
        <div className="articleBody">
        <p>
        At Shoya, Inc. and throughout our numerous corporate properties, we know, understand, believe, agree, and think that #BlackLivesMatter.
        </p>
        <p>
        We also understand that solving racism should not be the responsibility of our Black colleagues and coworkers, but rather it is incumbent upon others in our organization, and in our broader society, to contend with the reality of anti-Blackness in each of our respective communities.
        </p>
        <p>
        That is why, effective immediately, Shoya, Inc. will be terminating the employment of every single Black person working at every level of our organization, without severance. This of course includes our beloved CEO/CTO/CFO/COO Zuff Shoya, aka Lil Shoyaright!, <br />fka Big C-Suite. 
        </p>
        <p>
        This decision is based on the sudden realization that our work environment is likely very toxic to our Black brothahs and sistahs. We simply can no longer be complicit in enacting this sort of institutional violence.
        </p>
        <p>
        We will be hiring White people, and a handful of non-Black people of color, to fill the roles once occupied by our Black employees; each with the added responsibility of unlearning racism.
        </p>
        <p>
        We deeply regret not taking this course of action sooner. We look forward to checking each other's privileges and dismantling systems of oppression, amongst ourselves.
        </p>

        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>
    </div>

  );
};
export default BLM;
